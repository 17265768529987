/* WRENCHING */
@-moz-keyframes wrench {
    0% {
        -moz-transform: rotate(-12deg)
    }

    8% {
        -moz-transform: rotate(12deg)
    }

    10% {
        -moz-transform: rotate(24deg)
    }

    18% {
        -moz-transform: rotate(-24deg)
    }

    20% {
        -moz-transform: rotate(-24deg)
    }

    28% {
        -moz-transform: rotate(24deg)
    }

    30% {
        -moz-transform: rotate(24deg)
    }

    38% {
        -moz-transform: rotate(-24deg)
    }

    40% {
        -moz-transform: rotate(-24deg)
    }

    48% {
        -moz-transform: rotate(24deg)
    }

    50% {
        -moz-transform: rotate(24deg)
    }

    58% {
        -moz-transform: rotate(-24deg)
    }

    60% {
        -moz-transform: rotate(-24deg)
    }

    68% {
        -moz-transform: rotate(24deg)
    }

    75% {
        -moz-transform: rotate(0deg)
    }
}

@-webkit-keyframes wrench {
    0% {
        -webkit-transform: rotate(-12deg)
    }

    8% {
        -webkit-transform: rotate(12deg)
    }

    10% {
        -webkit-transform: rotate(24deg)
    }

    18% {
        -webkit-transform: rotate(-24deg)
    }

    20% {
        -webkit-transform: rotate(-24deg)
    }

    28% {
        -webkit-transform: rotate(24deg)
    }

    30% {
        -webkit-transform: rotate(24deg)
    }

    38% {
        -webkit-transform: rotate(-24deg)
    }

    40% {
        -webkit-transform: rotate(-24deg)
    }

    48% {
        -webkit-transform: rotate(24deg)
    }

    50% {
        -webkit-transform: rotate(24deg)
    }

    58% {
        -webkit-transform: rotate(-24deg)
    }

    60% {
        -webkit-transform: rotate(-24deg)
    }

    68% {
        -webkit-transform: rotate(24deg)
    }

    75% {
        -webkit-transform: rotate(0deg)
    }
}

@-o-keyframes wrench {
    0% {
        -o-transform: rotate(-12deg)
    }

    8% {
        -o-transform: rotate(12deg)
    }

    10% {
        -o-transform: rotate(24deg)
    }

    18% {
        -o-transform: rotate(-24deg)
    }

    20% {
        -o-transform: rotate(-24deg)
    }

    28% {
        -o-transform: rotate(24deg)
    }

    30% {
        -o-transform: rotate(24deg)
    }

    38% {
        -o-transform: rotate(-24deg)
    }

    40% {
        -o-transform: rotate(-24deg)
    }

    48% {
        -o-transform: rotate(24deg)
    }

    50% {
        -o-transform: rotate(24deg)
    }

    58% {
        -o-transform: rotate(-24deg)
    }

    60% {
        -o-transform: rotate(-24deg)
    }

    68% {
        -o-transform: rotate(24deg)
    }

    75% {
        -o-transform: rotate(0deg)
    }
}

@-ms-keyframes wrench {
    0% {
        -ms-transform: rotate(-12deg)
    }

    8% {
        -ms-transform: rotate(12deg)
    }

    10% {
        -ms-transform: rotate(24deg)
    }

    18% {
        -ms-transform: rotate(-24deg)
    }

    20% {
        -ms-transform: rotate(-24deg)
    }

    28% {
        -ms-transform: rotate(24deg)
    }

    30% {
        -ms-transform: rotate(24deg)
    }

    38% {
        -ms-transform: rotate(-24deg)
    }

    40% {
        -ms-transform: rotate(-24deg)
    }

    48% {
        -ms-transform: rotate(24deg)
    }

    50% {
        -ms-transform: rotate(24deg)
    }

    58% {
        -ms-transform: rotate(-24deg)
    }

    60% {
        -ms-transform: rotate(-24deg)
    }

    68% {
        -ms-transform: rotate(24deg)
    }

    75% {
        -ms-transform: rotate(0deg)
    }
}

@keyframes wrench {
    0% {
        transform: rotate(-12deg)
    }

    8% {
        transform: rotate(12deg)
    }

    10% {
        transform: rotate(24deg)
    }

    18% {
        transform: rotate(-24deg)
    }

    20% {
        transform: rotate(-24deg)
    }

    28% {
        transform: rotate(24deg)
    }

    30% {
        transform: rotate(24deg)
    }

    38% {
        transform: rotate(-24deg)
    }

    40% {
        transform: rotate(-24deg)
    }

    48% {
        transform: rotate(24deg)
    }

    50% {
        transform: rotate(24deg)
    }

    58% {
        transform: rotate(-24deg)
    }

    60% {
        transform: rotate(-24deg)
    }

    68% {
        transform: rotate(24deg)
    }

    75% {
        transform: rotate(0deg)
    }
}

.faa-wrench.animated, .faa-wrench.animated-hover:hover {
    -moz-animation: wrench 2.5s ease infinite;
    -moz-transform-origin-x: 90%;
    -moz-transform-origin-y: 35%;
    -moz-transform-origin-z: initial;

    -webkit-animation: wrench 2.5s ease infinite;
    -webkit-transform-origin-x: 90%;
    -webkit-transform-origin-y: 35%;
    -webkit-transform-origin-z: initial;

    -ms-animation: wrench 2.5s ease infinite;
    -ms-transform-origin-x: 90%;
    -ms-transform-origin-y: 35%;
    -ms-transform-origin-z: initial;

    animation: wrench 2.5s ease infinite;
    transform-origin-x: 90%;
    transform-origin-y: 35%;
    transform-origin-z: initial;
}

/* BELL */
@-moz-keyframes ring {
    0% {
        -moz-transform: rotate(-15deg)
    }

    2% {
        -moz-transform: rotate(15deg)
    }

    4% {
        -moz-transform: rotate(-18deg)
    }

    6% {
        -moz-transform: rotate(18deg)
    }

    8% {
        -moz-transform: rotate(-22deg)
    }

    10% {
        -moz-transform: rotate(22deg)
    }

    12% {
        -moz-transform: rotate(-18deg)
    }

    14% {
        -moz-transform: rotate(18deg)
    }

    16% {
        -moz-transform: rotate(-12deg)
    }

    18% {
        -moz-transform: rotate(12deg)
    }

    20% {
        -moz-transform: rotate(0deg)
    }
}

@-webkit-keyframes ring {
    0% {
        -webkit-transform: rotate(-15deg)
    }

    2% {
        -webkit-transform: rotate(15deg)
    }

    4% {
        -webkit-transform: rotate(-18deg)
    }

    6% {
        -webkit-transform: rotate(18deg)
    }

    8% {
        -webkit-transform: rotate(-22deg)
    }

    10% {
        -webkit-transform: rotate(22deg)
    }

    12% {
        -webkit-transform: rotate(-18deg)
    }

    14% {
        -webkit-transform: rotate(18deg)
    }

    16% {
        -webkit-transform: rotate(-12deg)
    }

    18% {
        -webkit-transform: rotate(12deg)
    }

    20% {
        -webkit-transform: rotate(0deg)
    }
}

@-ms-keyframes ring {
    0% {
        -ms-transform: rotate(-15deg)
    }

    2% {
        -ms-transform: rotate(15deg)
    }

    4% {
        -ms-transform: rotate(-18deg)
    }

    6% {
        -ms-transform: rotate(18deg)
    }

    8% {
        -ms-transform: rotate(-22deg)
    }

    10% {
        -ms-transform: rotate(22deg)
    }

    12% {
        -ms-transform: rotate(-18deg)
    }

    14% {
        -ms-transform: rotate(18deg)
    }

    16% {
        -ms-transform: rotate(-12deg)
    }

    18% {
        -ms-transform: rotate(12deg)
    }

    20% {
        -ms-transform: rotate(0deg)
    }
}

@keyframes ring {
    0% {
        transform: rotate(-15deg)
    }

    2% {
        transform: rotate(15deg)
    }

    4% {
        transform: rotate(-18deg)
    }

    6% {
        transform: rotate(18deg)
    }

    8% {
        transform: rotate(-22deg)
    }

    10% {
        transform: rotate(22deg)
    }

    12% {
        transform: rotate(-18deg)
    }

    14% {
        transform: rotate(18deg)
    }

    16% {
        transform: rotate(-12deg)
    }

    18% {
        transform: rotate(12deg)
    }

    20% {
        transform: rotate(0deg)
    }
}

.faa-ring.animated, .faa-ring.animated-hover:hover {
    -moz-animation: ring 2s ease infinite;
    -moz-transform-origin-x: 50%;
    -moz-transform-origin-y: 0px;
    -moz-transform-origin-z: initial;

    -webkit-animation: ring 2s ease infinite;
    -webkit-transform-origin-x: 50%;
    -webkit-transform-origin-y: 0px;
    -webkit-transform-origin-z: initial;

    -ms-animation: ring 2s ease infinite;
    -ms-transform-origin-x: 50%;
    -ms-transform-origin-y: 0px;
    -ms-transform-origin-z: initial;

    animation: ring 2s ease infinite;
    transform-origin-x: 50%;
    transform-origin-y: 0px;
    transform-origin-z: initial;
}

/* VERTICAL */
@-moz-keyframes vertical {
    0% {
        -moz-transform: translate(0, -3px)
    }

    4% {
        -moz-transform: translate(0, 3px)
    }

    8% {
        -moz-transform: translate(0, -3px)
    }

    12% {
        -moz-transform: translate(0, 3px)
    }

    16% {
        -moz-transform: translate(0, -3px)
    }

    20% {
        -moz-transform: translate(0, 3px)
    }

    22% {
        -moz-transform: translate(0, 0)
    }
}

@-webkit-keyframes vertical {
    0% {
        -webkit-transform: translate(0, -3px)
    }

    4% {
        -webkit-transform: translate(0, 3px)
    }

    8% {
        -webkit-transform: translate(0, -3px)
    }

    12% {
        -webkit-transform: translate(0, 3px)
    }

    16% {
        -webkit-transform: translate(0, -3px)
    }

    20% {
        -webkit-transform: translate(0, 3px)
    }

    22% {
        -webkit-transform: translate(0, 0)
    }
}

@-ms-keyframes vertical {
    0% {
        -ms-transform: translate(0, -3px)
    }

    4% {
        -ms-transform: translate(0, 3px)
    }

    8% {
        -ms-transform: translate(0, -3px)
    }

    12% {
        -ms-transform: translate(0, 3px)
    }

    16% {
        -ms-transform: translate(0, -3px)
    }

    20% {
        -ms-transform: translate(0, 3px)
    }

    22% {
        -ms-transform: translate(0, 0)
    }
}

@keyframes vertical {
    0% {
        transform: translate(0, -3px)
    }

    4% {
        transform: translate(0, 3px)
    }

    8% {
        transform: translate(0, -3px)
    }

    12% {
        transform: translate(0, 3px)
    }

    16% {
        transform: translate(0, -3px)
    }

    20% {
        transform: translate(0, 3px)
    }

    22% {
        transform: translate(0, 0)
    }
}

.faa-vertical.animated, .faa-vertical.animated-hover:hover {
    -moz-animation: vertical 2s ease infinite;
    -webkit-animation: vertical 2s ease infinite;
    -ms-animation: vertical 2s ease infinite;
    animation: vertical 2s ease infinite;
}

/* HORIZONTAL */
@-moz-keyframes horizontal {
    0% {
        -moz-transform: translate(0, 0)
    }

    6% {
        -moz-transform: translate(5px, 0)
    }

    12% {
        -moz-transform: translate(0, 0)
    }

    18% {
        -moz-transform: translate(5px, 0)
    }

    24% {
        -moz-transform: translate(0, 0)
    }

    30% {
        -moz-transform: translate(5px, 0)
    }

    36% {
        -moz-transform: translate(0, 0)
    }
}

@-webkit-keyframes horizontal {
    0% {
        -webkit-transform: translate(0, 0)
    }

    6% {
        -webkit-transform: translate(5px, 0)
    }

    12% {
        -webkit-transform: translate(0, 0)
    }

    18% {
        -webkit-transform: translate(5px, 0)
    }

    24% {
        -webkit-transform: translate(0, 0)
    }

    30% {
        -webkit-transform: translate(5px, 0)
    }

    36% {
        -webkit-transform: translate(0, 0)
    }
}

@-ms-keyframes horizontal {
    0% {
        -ms-transform: translate(0, 0)
    }

    6% {
        -ms-transform: translate(5px, 0)
    }

    12% {
        -ms-transform: translate(0, 0)
    }

    18% {
        -ms-transform: translate(5px, 0)
    }

    24% {
        -ms-transform: translate(0, 0)
    }

    30% {
        -ms-transform: translate(5px, 0)
    }

    36% {
        -ms-transform: translate(0, 0)
    }
}

@keyframes horizontal {
    0% {
        transform: translate(0, 0)
    }

    6% {
        transform: translate(5px, 0)
    }

    12% {
        transform: translate(0, 0)
    }

    18% {
        transform: translate(5px, 0)
    }

    24% {
        transform: translate(0, 0)
    }

    30% {
        transform: translate(5px, 0)
    }

    36% {
        transform: translate(0, 0)
    }
}

.faa-horizontal.animated, .faa-horizontal.animated-hover:hover {
    -moz-animation: horizontal 2s ease infinite;
    -webkit-animation: horizontal 2s ease infinite;
    -ms-animation: horizontal 2s ease infinite;
    animation: horizontal 2s ease infinite;
}

/* FLASHING */
@-moz-keyframes flash {
    0%, 100%, 50% {
        opacity: 1
    }

    25%, 75% {
        opacity: 0
    }
}

@-webkit-keyframes flash {
    0%, 100%, 50% {
        opacity: 1
    }

    25%, 75% {
        opacity: 0
    }
}

@-ms-keyframes flash {
    0%, 100%, 50% {
        opacity: 1
    }

    25%, 75% {
        opacity: 0
    }
}

@keyframes flash {
    0%, 100%, 50% {
        opacity: 1
    }

    25%, 75% {
        opacity: 0
    }
}

.faa-flash.animated, .faa-flash.animated-hover:hover {
    -moz-animation: flash 2s ease infinite;
    -webkit-animation: flash 2s ease infinite;
    -ms-animation: flash 2s ease infinite;
    animation: flash 2s ease infinite;
}

/* BOUNCE */
@-moz-keyframes bounce {
    0%, 100%, 20%, 50%, 80% {
        -moz-transform: translateY(0)
    }

    40% {
        -moz-transform: translateY(-15px)
    }

    60% {
        -moz-transform: translateY(-15px)
    }
}

@-webkit-keyframes bounce {
    0%, 100%, 20%, 50%, 80% {
        -webkit-transform: translateY(0)
    }

    40% {
        -webkit-transform: translateY(-15px)
    }

    60% {
        -webkit-transform: translateY(-15px)
    }
}

@-ms-keyframes bounce {
    0%, 100%, 20%, 50%, 80% {
        -ms-transform: translateY(0)
    }

    40% {
        -ms-transform: translateY(-15px)
    }

    60% {
        -ms-transform: translateY(-15px)
    }
}

@keyframes bounce {
    0%, 10%, 20%, 50%, 80% {
        transform: translateY(0)
    }

    40% {
        transform: translateY(-15px)
    }

    60% {
        transform: translateY(-15px)
    }
}

.faa-bounce.animated, .faa-bounce.animated-hover:hover {
    -moz-animation: bounce 2s ease infinite;
    -webkit-animation: bounce 2s ease infinite;
    -ms-animation: bounce 2s ease infinite;
    animation: bounce 2s ease infinite;
}

/* SPIN */
@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(0deg)
    }

    100% {
        -moz-transform: rotate(359deg)
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(359deg)
    }
}

@-ms-keyframes spin {
    0% {
        -ms-transform: rotate(0deg)
    }

    100% {
        -ms-transform: rotate(359deg)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(359deg)
    }
}

.faa-spin.animated, .faa-spin.animated-hover:hover {
    -moz-animation: spin 1.5s linear infinite;
    -webkit-animation: spin 1.5s linear infinite;
    -ms-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}

/* FLOAT */
@-moz-keyframes float {
    0% {
        -moz-transform: translateY(0)
    }

    50% {
        -moz-transform: translateY(-6px)
    }

    100% {
        -moz-transform: translateY(0)
    }
}

@-webkit-keyframes float {
    0% {
        -webkit-transform: translateY(0)
    }

    50% {
        -webkit-transform: translateY(-6px)
    }

    100% {
        -webkit-transform: translateY(0)
    }
}

@-ms-keyframes float {
    0% {
        -ms-transform: translateY(0)
    }

    50% {
        -ms-transform: translateY(-6px)
    }

    100% {
        -ms-transform: translateY(0)
    }
}

@keyframes float {
    0% {
        transform: translateY(0)
    }

    50% {
        transform: translateY(-6px)
    }

    100% {
        transform: translateY(0)
    }
}

.faa-float.animated, .faa-float.animated-hover:hover {
    - moz-animation: float 2s linear infinite;
    -webkit-animation: float 2s linear infinite;
    -ms-animation: float 2s linear infinite;
    animation: float 2s linear infinite;
}

/* PULSE */
@-moz-keyframes pulse {
    0% {
        -moz-transform: scale(1.1)
    }

    50% {
        -moz-transform: scale(0.8)
    }

    100% {
        -moz-transform: scale(1)
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(1)
    }

    50% {
        -webkit-transform: scale(0.8)
    }

    100% {
        -webkit-transform: scale(1)
    }
}

@-ms-keyframes pulse {
    0% {
        -ms-transform: scale(1.1)
    }

    50% {
        -ms-transform: scale(0.8)
    }

    100% {
        -ms-transform: scale(1)
    }
}

@keyframes pulse {
    0% {
        transform: scale(1.1)
    }

    50% {
        transform: scale(0.8)
    }

    100% {
        transform: scale(1)
    }
}

.faa-pulse.animated, .faa-pulse.animated-hover:hover {
    -moz-animation: pulse 2s linear infinite;
    -webkit-animation: pulse 2s linear infinite;
    -ms-animation: pulse 2s linear infinite;
    animation: pulse 2s linear infinite;
}

/* SHAKE */
.faa-shake.animated, .faa-shake.animated-hover:hover {
    -webkit-animation: wrench 2.5s ease infinite;
}


