/*!
 * Lightbox for Bootstrap 3 by @ashleydw
 * https://github.com/ashleydw/lightbox
 *
 * License: https://github.com/ashleydw/lightbox/blob/master/LICENSE
 */
.ekko-lightbox-container {
  position: relative;
}
.ekko-lightbox-nav-overlay {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ekko-lightbox-nav-overlay a {
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
  color: #fff;
  font-size: 30px;
  height: 100%;
  width: 49%;
  display: block;
  text-shadow: 2px 2px 4px #000;
  filter: dropshadow(color=#000, offx=2, offy=2);
  z-index: 100;
}
.ekko-lightbox-nav-overlay a:empty {
  width: 49%;
}
.ekko-lightbox a:hover {
  opacity: 1;
  text-decoration: none;
}
.ekko-lightbox .glyphicon-chevron-left {
  padding-left: 15px;
  float: left;
  left: 0;
  text-align: left;
}
.ekko-lightbox .glyphicon-chevron-right {
  padding-right: 15px;
  float: right;
  right: 0;
  text-align: right;
}
.ekko-lightbox .modal-footer {
  text-align: left;
}
